.risk-setting-header-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.risk-setting-visibility-icon-button {
  pointer-events: auto;
  opacity: 1;
}

.risk-setting-info-button {
  width: 85px;
}