.risk-settings-view {
  position: relative;
}

.risk-settings-fold-controls {
  position: absolute;
  right: 0px;
  display: flex;
  gap: 5px;
}

.risk-avoidance-view {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
 
.risk-settings-run-calculation-button {
  margin-top: 25px;
}