.risk-settings-group-view-header {
  padding: 0px 10px 0px 10px !important;
  width: 100%;
}

.risk-settings-group-view-content-wrapper {
  padding: 0px !important;
}

.risk-settings-group-view-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  margin: 0px 10px 10px 10px;
}